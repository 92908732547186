<template>
  <div style="margin: 20px;">
    <h3>Resolutions<a type="text" style="font-size: 14px; color: green;" @click="add()"> New <i class="fas fa-plus-square"/></a></h3>
    <el-row>
      <el-alert
        v-if="request_data_add > 0"
        :title="'(' + request_data_add + ') New resolutions added. Click refresh button to reload.'"
        type="success"
        show-icon
      />
    </el-row>
    <el-row>
      <el-alert
        v-if="request_data_update > 0"
        :title="'(' + request_data_update + ') Resolutions updated. Click refresh button to reload.'"
        type="success"
        show-icon
      />
    </el-row>
    <el-card shadow="never">
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form :inline="true" label-position="right">
            <el-form-item>
              <el-date-picker v-model="year_s" size="medium" type="year" placeholder="Year" style="width: 100px;" />
            </el-form-item>
            <el-form-item>
              <el-select v-model="reso_type_s" size="medium" placeholder="Resolution" style="width: 140px" clearable>
                <el-option v-for="select in select_minutes_label" :key="select.label" :label="select.label" :value="select.label" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="title_s" size="medium" placeholder="Title" style="width: 300px;" clearable />
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Search" placement="top">
                <el-button :icon="icon_search" type="primary" size="medium" @click="search()" />
              </el-tooltip>
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Refresh" placement="top">
                <el-button :icon="icon_refresh" type="info" size="medium" @click="refresh()" />
              </el-tooltip>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" align="right">
          <el-form :inline="true" label-position="right">
            <el-form-item>
              <el-checkbox v-model="naf">No Attached File</el-checkbox>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-table v-loading="loading" ref="singleTable" :data="resolutions" size="mini" style="width: 100%; font-size: 14px;" highlight-current-row @current-change="handleCurrentChange">
          <el-table-column type="expand" label="..." width="48">
            <template slot-scope="props">
              <el-row>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <p v-if="props.row.reso_type === 'BOR'" class="para"><strong>Resolution: </strong>BOR - Board of Regents</p>
                  <p v-if="props.row.reso_type === 'UAC'" class="para"><strong>Resolution: </strong>UAC - University Academic Council</p>
                  <p v-if="props.row.reso_type === 'UADCO'" class="para"><strong>Resolution: </strong>UADCO - University Administrative Council</p>
                  <p class="para"><strong>Year: </strong>{{ props.row.year }}</p>
                  <p class="para"><strong>Resolution no.: </strong>{{ props.row.resolution_no }}</p>
                  <p style="word-break: break-word" class="para"><strong>Title: </strong>{{ props.row.title }}</p>
                  <p v-if="props.row.type" class="para"><strong>Minutes: </strong>Minutes of the {{ props.row.minute_no }} {{ props.row.type }} Meeting</p>
                  <p v-if="props.row.doc_code" class="para"><strong>Document code: </strong>{{ props.row.doc_code }}</p>
                  <p v-if="props.row.rev_num" class="para"><strong>Revision no.: </strong>{{ props.row.rev_num }}</p>
                  <p v-if="props.row.imp_date" class="para"><strong>Implementation date: </strong>{{ getFormattedDate(props.row.imp_date,'MMMM DD, Y') }}</p>
                  <p v-if="props.row.con_num" class="para"><strong>Control no.: </strong>{{ props.row.con_num }}</p>
                  <p v-if="props.row.add_by_personnel" class="para">
                    <strong>Added by: </strong>
                    <span :title="getFormattedDate(props.row.created_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.add_by_personnel.name }}</span>
                  </p>
                  <p v-if="props.row.update_by_personnel" class="para">
                    <strong>Updated by: </strong>
                    <span :title="getFormattedDate(props.row.updated_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.update_by_personnel.name }}</span>
                  </p>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column prop="year" label="Year" width="100" />
          <el-table-column prop="reso_type" label="Resolution" width="100" />
          <el-table-column prop="resolution_no" label="Reso. no." width="100" />
          <el-table-column label="Title" width="900">
            <template slot-scope="scope">
              <span v-if="scope.row.title.length < 120" style="word-break: break-word"> {{ scope.row.title }} </span>
              <span v-else style="word-break: break-word"> {{ scope.row.title.substring(0, 120) + " ..." }} </span>
            </template>
          </el-table-column>
          <el-table-column width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.path" style="word-break: break-word"><el-tag type="success" effect="plain" size="medium" style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">File Uploaded</el-tag></span>
              <span v-else style="word-break: break-word"><el-tag type="danger" effect="plain" size="medium" style="width: 120px;">No Attached File</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Operation (s)" width="150" align="right">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip v-if="scope.row.path" :key="'iftpr1' + scope.row.id" content="View Attached File" placement="top">
                  <el-button :key="'ifbnr2' + scope.row.id" type="primary" icon="el-icon-document" size="mini" style="margin-right: 10px;" @click="viewFile(scope.row.path)" />
                </el-tooltip>
                <el-tooltip :key="'tpr3' + scope.row.id" content="Edit" placement="top">
                  <el-button :key="'bnr4' + scope.row.id" type="primary" icon="el-icon-edit" size="mini" style="margin-right: 10px;" @click="edit(scope.$index)" />
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <div align="center" style="margin-top: 10px;">
          <el-pagination :page-size="meta.per_page" :pager-count="5" :total="meta.total" :current-page.sync="meta.page" layout="total, prev, pager, next" @current-change="getAll()" />
        </div>
      </el-row>
    </el-card>
    <el-dialog v-if="selected" :visible.sync="editable" :title="title_dialog" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" top="5vh" width="70%">
      <el-row style="margin: -50px 0;">
        <el-divider />
        <p><strong>Note:</strong> Fields marked with asterisk (<span style="color: #f56c6c; font-weight: bold;">*</span>) are required to be filled.</p>
        <el-form ref="selected" :model="selected" :rules="rules">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="8">
              <el-form-item label="Resolution" prop="reso_type">
                <el-select v-if="editable" v-model="selected.reso_type" size="medium" placeholder="Please select resolution" clearable @change="checkMinutesType(selected.reso_type)">
                  <el-option v-for="select in select_minutes_label" :key="select.label" :label="select.label" :value="select.label" />
                </el-select>
              </el-form-item>
              <el-form-item label="Year" prop="year">
                <el-date-picker v-if="editable" v-model="selected.year" type="year" size="medium" placeholder="Please pick a year" style="width: 100%;" />
              </el-form-item>
              <el-form-item label="Resolution no." prop="resolution_no">
                <el-input v-if="editable" v-model.number="selected.resolution_no" type="number" min="1" size="medium" placeholder="Please input resolution no." style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Title" prop="title">
                <el-input v-if="editable" v-model="selected.title" :autosize="{ minRows: 6 }" type="textarea" placeholder="Please input title" style="width: 100%; word-break: keep-all;" clearable />
              </el-form-item>
              <el-form-item v-if="selected.reso_type === 'BOR'" label="Minutes">
                <el-select v-if="editable" v-model="selected.minute_id" size="medium" placeholder="Please select minutes" style="width: 100%" filterable clearable>
                  <el-option v-for="minute in select_minutes" :key="minute.id" :value="minute.id" :label="'Minutes of the ' + minute.minute_no + ' ' + minute.type + ' Meeting | ' + minute.venue" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="8">
              <el-form-item label="Document code">
                <el-input v-model="selected.doc_code" size="medium" placeholder="Please input document code" style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Revision no.">
                <el-input v-model.number="selected.rev_num" type="number" min="1" size="medium" placeholder="Please input revision no." style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Implementation date">
                <el-date-picker v-model="selected.imp_date" type="date" size="medium" placeholder="Please input implementation date" style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Control no.">
                <el-input v-model="selected.con_num" size="medium" placeholder="Please input control no." style="width: 100%;" clearable />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="8">
              <el-form-item label="Attached File">
                <resolution-uploader-component v-if="editable" :path.sync="selected.path" class="my-upload-job" @setAttachment="selected.path = $event" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="submitForm('selected')">Save</el-button>
        <el-button type="danger" size="medium" @click="submitClose('selected')">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="view_attach_form"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeFile"
      width="60%"
      top="5vh">
      <div>
        <embed :key="view_file" :src="view_file" style="width: 100%; height: 700px;">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Resolutions } from '@/api/records/resolutions'
import { SelectMinutes } from '@/api/records/select_minutes'
import { Notification } from 'element-ui'
import ResolutionUploaderComponent from '@/views/layout/components/Records/ResolutionUploaderComponent'

export default {
  name: 'Resolutions',
  components: { ResolutionUploaderComponent },
  data() {
    return {
      loading: false,
      meta: {
        page: 1
      },
      resolutions: [],
      editable: false,
      title_dialog: null,
      select_minutes: [],
      type: [],
      select_minutes_label: [
        { label: 'BOR' },
        { label: 'UAC' },
        { label: 'UADCO' }
      ],
      year_s: null,
      reso_type_s: null,
      title_s: null,
      naf: false,
      path_s: null,
      selected: {
        reso_type: [],
        year: '',
        resolution_no: '',
        title: '',
        minute_id: '',
        doc_code: '',
        rev_num: '',
        imp_date: '',
        con_num: '',
        path: ''
      },
      rules: {
        reso_type: [
          { required: true, message: 'Resolution is required' }
        ],
        year: [
          { required: true, message: 'Year is required' }
        ],
        resolution_no: [
          { required: true, message: 'Resolution no. is required' },
          { type: 'number', message: 'Resolution no. must be a number' }
        ],
        title: [
          { required: true, message: 'Title is required' }
        ]
      },
      request_data_add: 0,
      request_data_update: 0,
      cur_personnel_id: this.$store.getters.user.currentUser.id,
      base_url: this.$store.getters.base_url,
      icon_search: 'el-icon-search',
      icon_refresh: 'el-icon-refresh',
      view_file: null,
      view_attach_form: false,
      loading_min: false
    }
  },
  watch: {
    naf: function() {
      this.meta.page = 1
      if (this.naf === true) {
        this.year_s = null
        this.reso_type_s = null
        this.title_s = null
        this.path_s = 1
      } else {
        this.year_s = null
        this.reso_type_s = null
        this.title_s = null
        this.path_s = null
      }
      this.getAll()
    }
  },
  created: function() {
    this.$echo.channel('resolutions').listen('RecordsResolutionsEvent', (res) => {
      this.action = res.data
      if (this.action.action === 'add') {
        if (this.action.action_by !== this.cur_personnel_id) {
          this.request_data_add = this.request_data_add + 1
        }
      }
      if (this.action.action === 'edit') {
        if (this.action.action_by !== this.cur_personnel_id) {
          this.request_data_update = this.request_data_update + 1
        }
      }
    })
    this.getAll()
  },
  destroyed() {
    this.$echo.leave('resolutions')
  },
  methods: {
    getAll() {
      this.loading = true
      this.selected = null
      if (this.year_s !== null) {
        this.year_s = this.getFormattedYear(this.year_s)
      }
      Resolutions({
        page: this.meta.page,
        year: this.year_s,
        reso_type: this.reso_type_s,
        title: this.title_s,
        path: this.path_s
      }).then(res => {
        this.resolutions = res.data.resolutions
        this.meta = res.data.meta
        this.loading = false
        this.icon_search = 'el-icon-search'
        this.icon_refresh = 'el-icon-refresh'
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    search() {
      this.icon_search = 'el-icon-loading'
      this.meta.page = 1
      this.getAll()
    },
    refresh() {
      this.icon_refresh = 'el-icon-loading'
      this.year_s = null
      this.reso_type_s = null
      this.title_s = null
      this.naf = false
      this.path_s = null
      this.request_data_add = 0
      this.request_data_update = 0
      this.meta.page = 1
      this.getAll()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          Notification.error({
            title: 'Error',
            message: 'Unable to save',
            duration: 5 * 1000
          })
          return false
        }
      })
    },
    submitClose(formName) {
      this.$refs[formName].resetFields()
      this.editable = false
    },
    // viewFile(file) {
    //   window.open(this.base_url + '/records/resolutions/download/' + file, '_blank')
    // },
    viewFile(path) {
      this.view_file = this.base_url + '/records/resolutions/download/' + path
      this.view_attach_form = true
    },
    closeFile() {
      this.view_file = null
      this.view_attach_form = false
    },
    add() {
      this.title_dialog = 'New Resolution'
      this.selected = {}
      this.selected.orig_path = null
      this.editable = true
    },
    edit(index) {
      this.title_dialog = 'Edit Resolution'
      this.selected = Object.assign({}, this.resolutions[index])
      this.selected.year = "'" + this.selected.year + "'"
      this.selected.orig_path = this.selected.path
      this.fetchMinutes()
      this.editable = true
    },
    save: function() {
      this.selected.year = this.getFormattedYear(this.selected.year)
      if (this.selected.imp_date) {
        this.selected.imp_date = this.getFormattedDate(this.selected.imp_date)
      }
      var action = this.selected.id ? 'update' : 'create'
      Resolutions(this.selected, action).then(res => {
        if (res.data.error === true) {
          Notification.error({
            title: 'Error',
            message: 'Data already existed',
            duration: 5 * 1000
          })
        } else {
          Notification.success({
            title: 'Success',
            message: 'Resolution has been saved successfully',
            duration: 5 * 1000
          })
          this.submitClose('selected')
          this.getAll()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    checkMinutesType(type) {
      if (type === 'BOR') this.fetchMinutes()
    },
    fetchMinutes() {
      this.loading_min = true
      SelectMinutes({
        type: this.selected.reso_type,
        per_page: 9999
      }).then(res => {
        this.select_minutes = res.data.select_minutes
        this.loading_min = false
      }).catch(error => {
        console.log(error)
        this.loading_min = false
      })
    },
    getFormattedTimeStamps() {
      return this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    },
    getFormattedDate(date, format = 'YYYY-MM-DD') {
      return this.$moment(date).format(format)
    },
    getFormattedYear(year) {
      return this.$moment(new Date(year)).format('YYYY')
    },
    handleCurrentChange(val) {
      this.currentRow = val
    }
  }
}
</script>
